import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { VueCookieNext } from "vue-cookie-next";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useRoute, useRouter } from "vue-router";
export interface User {
  name: string;
  username: string;
  password: string;
  token: string;
}
export interface UserAuthInfo {
  errors: Array<string>;
  user: User;
  isAuthenticated: boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = [];
  user = {} as User;
  isAuthenticated = !!JwtService.getToken();
  $router: any;

  get currentUser(): User {
    return this.user;
  }

  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  get getErrors(): Array<string> {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_AUTH](user) {
    this.isAuthenticated = true;
    this.user = user.data.user_info;
    this.errors = [];
    JwtService.saveToken(user.data.token);
    ApiService.setHeader();
  }

  @Mutation
  [Mutations.SET_LOGIN_DATA](user) {
    this.isAuthenticated = true;
    this.user = user;
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }

  @Mutation
  [Mutations.SET_PASSWORD](password) {
    this.user.password = password;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
    JwtService.destroyToken();
  }

  @Action
  [Actions.LOGIN](credentials) {
    return new Promise<void>((resolve, reject) => {
      VueCookieNext.config({ expire: '12h', domain: '.tappware.com' });
      // VueCookieNext.config({ expire: '1h', domain: 'localhost' });
      ApiService.post("auth/login", credentials)
        .then(({ data }) => {
          if (data.status == "error") {
            Swal.fire({
              text: data.message,

              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText:
                data.message == "UserName or Password Not Match!"
                  ? "Forgot Password"
                  : " Reset Password!",
            }).then((result) => {
              if (result.isConfirmed) {
                if (data.message == "UserName or Password Not Match!") {
                  window.location.href =
                    "https://seip-doptor.tappware.com/forgot-pass";
                } else {
                  window.location.href =
                    "https://seip-doptor.tappware.com/password-expire-reset";
                }
                // const router = useRouter();
                // router.push({ name: 'dashboard' });
              }
            });
            this.context.commit(Mutations.SET_AUTH, data);
            resolve();
          }
          if (data.data.token && data.status == "success") {
            VueCookieNext.setCookie("day_diff", data.data.day_diff);
            VueCookieNext.setCookie(
              "notification_message",
              data.data.notification_message
            );

            VueCookieNext.setCookie("_seip_user", data.data.user_info);

            VueCookieNext.setCookie(
              "_seip_employee_id",
              data.data.user_info.employee.id
            );
            VueCookieNext.setCookie(
              "_entity_id",
              data.data.user_info.employee.entity_organogram[0].entity_id
            );
            VueCookieNext.setCookie(
              "_institute_info_id",
              data.data.user_info.employee.entity_organogram[0]
                .institute_info_id
            );
            VueCookieNext.setCookie(
              "_seip_role_id",
              data.data.user_info.employee.entity_organogram[0].entitytyperole
                .id
            );
            VueCookieNext.setCookie(
              "_seip_entity_type",
              data.data.user_info.employee.entity_organogram[0].entitytyperole
                .entity_type_id
            );
            this.context.commit(Mutations.SET_AUTH, data);
            resolve();
          } else {
            this.context.commit(Mutations.SET_ERROR, data.message);
            reject();
          }
        })
        .catch(({ response }) => {
          console.log(response);

          // console.log(response.message);
          this.context.commit(Mutations.SET_ERROR, response);
          reject();
        });
    });
  }

  @Action
  [Actions.LOGOUT]() {
    localStorage.clear();
    VueCookieNext.removeCookie("_seip_user", {
      path: "/",
      domain: ".tappware.com",
    });
    VueCookieNext.removeCookie("_seip_employee_id", {
      path: "/",
      domain: ".tappware.com",
    });
    VueCookieNext.removeCookie("_entity_id", {
      path: "/",
      domain: ".tappware.com",
    });
    VueCookieNext.removeCookie("_institute_info_id", {
      path: "/",
      domain: ".tappware.com",
    });
    VueCookieNext.removeCookie("_seip_role_id", {
      path: "/",
      domain: ".tappware.com",
    });
    VueCookieNext.removeCookie("_seip_entity_type", {
      path: "/",
      domain: ".tappware.com",
    });
    console.log("logout done");
    this.context.commit(Mutations.PURGE_AUTH);
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("forgot_password", payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  // @Action
  // [Actions.VERIFY_AUTH]() {
  //   if (JwtService.getToken()) {
  //     ApiService.setHeader();
  //     ApiService.get("auth/verify")
  //       .then(({ data }) => {
  //         if (!data.status) {
  //           this.context.commit(Mutations.PURGE_AUTH);
  //         }
  //       })
  //       .catch(({ response }) => {
  //         this.context.commit(Mutations.SET_ERROR, response.data.errors);
  //       });
  //   } else {
  //     this.context.commit(Mutations.PURGE_AUTH);
  //   }
  // }

  // @Action
  // [Actions.UPDATE_USER](payload) {
  //   ApiService.setHeader();
  //   return new Promise<void>((resolve, reject) => {
  //     ApiService.post("update_user", payload)
  //       .then(({ data }) => {
  //         this.context.commit(Mutations.SET_USER, data);
  //         resolve();
  //       })
  //       .catch(({ response }) => {
  //         this.context.commit(Mutations.SET_ERROR, response.data.errors);
  //         reject();
  //       });
  //   });
  // }
}
