import store from '@/store';
//import { Mutations, Actions } from "@/store/enums/StoreEnums";
import { Mutations } from '@/store/enums/StoreEnums';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    // redirect: '/sign-in',
    redirect: '/invoice-check',
    component: () => import('@/layout/CourseSettings.vue'),
    // component: () => import('@/components/page-layouts/Auth.vue'),
    // component: () => import('@/layout/Association.vue'),

    children: [
      {
        path: '/add-invoice',
        name: 'add-invoice',
        // component: () => import('@/views/Trainer/AddTrainer.vue'),
        component: () => import('@/views/fInmanPages/FinmanInvoice.vue'),
      },
      {
        path: '/edit-invoice',
        name: 'edit-invoice',
        // component: () => import('@/views/Trainer/AddTrainer.vue'),
        component: () => import('@/views/fInmanPages/FinmanInvoiceEdit.vue'),
      },
      {
        path: '/view-invoice',
        name: 'view-invoice',
        // component: () => import('@/views/Trainer/AddTrainer.vue'),
        component: () => import('@/views/fInmanPages/FinmanInvoiceView.vue'),
      },
      {
        path: '/purchase-invoice',
        name: 'purchase-invoice',
        // component: () => import('@/views/Trainer/AddTrainer.vue'),
        component: () => import('@/views/fInmanPages/FinmanPurchaseEdit.vue'),
      },
      {
        path: '/add-product',
        name: 'add-product',
        component: () => import('@/views/fInmanPages/Product.vue'),
      },
      {
        path: '/product-category',
        name: 'add-category',
        component: () => import('@/views/fInmanPages/ProductCategory.vue'),
      },
      {
        path: '/add-unit',
        name: 'add-unit',
        component: () => import('@/views/fInmanPages/AddUnit.vue'),
      },
      {
        path: '/add-purchase',
        name: 'add-purchase',
        component: () => import('@/views/fInmanPages/FInmanPurchase.vue'),
      },
      {
        path: '/sales-invoices',
        name: 'sales-invoices',
        component: () => import('@/views/fInmanPages/SalesInvoices.vue'),
      },
      {
        path: '/purchase-invoices',
        name: 'purchase-invoices',
        component: () => import('@/views/fInmanPages/PurchaseInvoices.vue'),
      },
      {
        path: '/customers',
        name: 'customers',
        component: () => import('@/views/fInmanPages/customers.vue'),
      },
      {
        path: '/supplier',
        name: 'supplier',
        component: () => import('@/views/fInmanPages/Supplier.vue'),
      },
      {
        path: '/sales-report',
        name: 'salesReport',
        component: () => import('@/views/fInmanPages/salesReport.vue'),
      },
      {
        path: '/salesman',
        name: 'salesMan',
        component: () => import('@/views/fInmanPages/salesMan.vue'),
      },
      {
        path: '/bill-collection',
        name: 'billCollection',
        component: () => import('@/views/fInmanPages/billCollection.vue'),
      },

      // {
      //   path: '/sign-in',
      //   name: 'sign-in',
      //   component: () => import('@/views/authentication/SignIn.vue'),
      // },
      // {
      //   path: '/forgot-pass',
      //   name: 'forgot-pass',
      //   component: () => import('@/views/authentication/ForgotPassword.vue'),
      // },
      // {
      //   path: '/resetPassword/:token',
      //   name: 'resetPassword',
      //   component: () => import('@/views/authentication/ResetPassword.vue'),
      // },
      // {
      //   path: '/password-expire-reset',
      //   name: 'ExpirePasswordReset',
      //   component: () =>
      //     import('@/views/authentication/ResetExpirePassword.vue'),
      // },
      // {
      //   path: '/sign-switch',
      //   name: 'sign-switch',
      //   component: () => import('@/views/authentication/Switcher.vue'),
      // },

      // {
      //   path: '/sign-up',
      //   name: 'sign-up',
      //   component: () => import('@/views/authentication/SignUp.vue'),
      // },
    ],
  },

  {
    path: '/invoice-check',
    name: 'invoice-check',
    component: () => import('@/views/fInmanPages/InvoiceTokenCheck.vue'),
  },
  {
    // the 404 route, when none of the above matches
    path: '/404',
    name: '404',
    component: () => import('@/views/authentication/Error404.vue'),
  },
  {
    path: '/500',
    name: '500',
    component: () => import('@/views/authentication/Error500.vue'),
  },
  // {
  //   path: '/trainee-register',
  //   name: 'Trainee Registration',
  //   component: () => import('@/views/Trainne/TrainneRegistration.vue'),
  //   props: true,
  // },
  // Routes for Mobile App
  // {
  //   path: '/institute-list/:id',
  //   name: 'single institute mobile',
  //   component: () => import('@/views/WebViews/SingleInstitute.vue'),
  // },
  // {
  //   path: '/entity-list/:id',
  //   name: 'single entity mobile',
  //   component: () => import('@/views/WebViews/SingleEntity.vue'),
  // },
  // {
  //   path: '/course-detail/:id',
  //   name: 'courseProfileDetails mobile',
  //   component: () => import('@/views/WebViews/CourseDetails.vue'),
  // },
  // {
  //   path: '/assessor-view/:id',
  //   name: 'assessor view mobile',
  //   component: () => import('@/views/WebViews/ListView.vue'),
  // },
  // {
  //   path: '/trainer-view/:id',
  //   name: 'trainer view mobile',
  //   component: () => import('@/views/WebViews/ListViewTrainer.vue'),
  // },

  {
    path: '/:pathMatch(.*)*',
    redirect: '/404',
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(() => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  //store.dispatch(Actions.VERIFY_AUTH);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
